import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import { Home, Music, Movies, MovieTrailers, TvShows, Comedians, Sports, VideoGames, GameTrailers, Memes } from "./CategoryPage"

// 'songs', 'movies', 'comedy', 'tvshows', 'sports', 'memes', 'other', 'none', 'movie-trailers', 'video-games', 'game-trailers', 'meta'
export const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/music">Music</Link>
        </li>
        <li>
          <Link to="/movies">Movies</Link>
        </li>
        <li>
          <Link to="/movie-trailers">Movie Trailers</Link>
        </li>
        <li>
          <Link to="/tv-shows">TV Shows</Link>
        </li>
        <li>
          <Link to="/comedians">Comedians</Link>
        </li>
        <li>
          <Link to="/sports">Sports</Link>
        </li>
        <li>
          <Link to="/video-games">Video Games</Link>
        </li>
        <li>
          <Link to="/game-trailers">Game Trailers</Link>
        </li>
        <li>
          <Link to="/memes">Memes</Link>
        </li>
      </ul>
    </nav>
  )
}

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/music">
            <Music />
          </Route>
          <Route exact path="/movies">
            <Movies />
          </Route>
          <Route exact path="/movie-trailers">
            <MovieTrailers />
          </Route>
          <Route exact path="/tv-shows">
            <TvShows />
          </Route>
          <Route exact path="/comedians">
            <Comedians />
          </Route>
          <Route exact path="/sports">
            <Sports />
          </Route>
          <Route exact path="/video-games">
            <VideoGames />
          </Route>
          <Route exact path="/game-trailers">
            <GameTrailers />
          </Route>
          <Route exact path="/memes">
            <Memes />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
