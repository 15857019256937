import React from "react"

const CategoryPage = (props: { title: string, contentType: string }) => {
    return (
        <h2>{props.title}</h2>
    )
}

export const Home = () => {
    return <CategoryPage title="Home" contentType="" />
}

export const Music = () => {
    return <CategoryPage title="Music" contentType="songs" />
}

export const Movies = () => {
    return <CategoryPage title="Movies" contentType="movies" />
}

export const MovieTrailers = () => {
    return <CategoryPage title="Movie Trailers" contentType="movie-trailers" />
}

export const TvShows = () => {
    return <CategoryPage title="TV Shows" contentType="tvshows" />
}

export const Comedians = () => {
    return <CategoryPage title="Comedians" contentType="comedy" />
}

export const Sports = () => {
    return <CategoryPage title="Sports" contentType="sports" />
}

export const VideoGames = () => {
    return <CategoryPage title="Video Games" contentType="video-games" />
}

export const GameTrailers = () => {
    return <CategoryPage title="Game Trailers" contentType="game-trailers" />
}

export const Memes = () => {
    return <CategoryPage title="Memes" contentType="memes" />
}

export default CategoryPage